import React, { useEffect } from "react"

export default function RedirectCancel() {
  useEffect(() => {
    const paymentRedirect = localStorage.paymentRedirect
    const paymentType = localStorage.paymentType
    localStorage.removeItem("paymentRedirect")
    localStorage.removeItem("paymentType")
    if (!paymentRedirect || paymentRedirect === undefined) {
      window.location.replace("/proxies")
      return
    }
    if (paymentType === "subscription") {
      window.location.replace("/checkout" + paymentRedirect)
    } else {
      window.location.replace("/settings/billing")
    }
  }, [])

  return <div />
}
